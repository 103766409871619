/* App.css */
body {
  margin: 0;
  padding: 0;
  background-color: #000;
  font-family: "Mega Man 10", sans-serif;
  color: #24f29c;
  font-size: 1rem;
  line-height: 1.6;
  font-weight: 400;
  letter-spacing: 0.1em;
  text-transform: uppercase;
}

#container {
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  z-index: -1;
}

h1 {
  margin-top: 20px;
  margin-bottom: 10px;
  font-size: 4rem;
  line-height: 1.2;
  font-weight: 700;
}

h2 {
  margin-top: 20px;
  margin-bottom: 10px;
  font-size: 2rem;
  line-height: 1.2;
  font-weight: 700;
}

a {
  color: #24f29c;
}

.page-padding {
  padding-right: 2rem;
  padding-left: 2rem;
}

.container-large {
  width: 100%;
  max-width: 100%;
  margin-right: auto;
  margin-left: auto;
}

.section-teaser {
  -webkit-box-pack: center;
  -webkit-justify-content: center;
  -ms-flex-pack: center;
  justify-content: center;
}

.main-wrapper {
  position: relative;
  left: 0%;
  top: 0%;
  right: 0%;
  bottom: 0%;
  display: -webkit-box;
  display: -webkit-flex;
  display: -ms-flexbox;
  display: flex;
  overflow: auto;
  min-height: 100vh;
  -webkit-box-orient: horizontal;
  -webkit-box-direction: normal;
  -webkit-flex-direction: row;
  -ms-flex-direction: row;
  flex-direction: row;
  -webkit-box-pack: center;
  -webkit-justify-content: center;
  -ms-flex-pack: center;
  justify-content: center;
  -webkit-box-align: stretch;
  -webkit-align-items: stretch;
  -ms-flex-align: stretch;
  align-items: stretch;
}

.teaser-component {
  position: relative;
  z-index: 1;
  display: -webkit-box;
  display: -webkit-flex;
  display: -ms-flexbox;
  display: flex;
  padding-top: 10vh;
  -webkit-box-orient: vertical;
  -webkit-box-direction: normal;
  -webkit-flex-direction: column;
  -ms-flex-direction: column;
  flex-direction: column;
  -webkit-box-pack: start;
  -webkit-justify-content: flex-start;
  -ms-flex-pack: start;
  justify-content: flex-start;
  -webkit-box-align: center;
  -webkit-align-items: center;
  -ms-flex-align: center;
  align-items: center;
  -webkit-align-self: stretch;
  -ms-flex-item-align: stretch;
  align-self: stretch;
  -webkit-box-flex: 1;
  -webkit-flex: 1;
  -ms-flex: 1;
  flex: 1;
  border-radius: 4px;
  text-align: center;
}

.teaser-title__jp {
  margin-top: 0px;
  margin-bottom: 0px;
  font-size: 3rem;
  font-weight: 400;
}

.teaser-title {
  margin-top: 1rem;
  margin-bottom: 0px;
  font-size: 3rem;
  font-weight: 400;
}

.teaser-desc {
  max-width: 40%;
  margin: 2rem auto 0;
  font-size: 1.5rem;
}

.teaser-ig {
  margin-top: 4rem;
  padding: 0.75em 1.5em;
  border-radius: 4px;
  -webkit-transition: background-color 200ms
    cubic-bezier(0.6, -0.28, 0.735, 0.045);
  transition: background-color 200ms cubic-bezier(0.6, -0.28, 0.735, 0.045);
  font-size: 0.75rem;
  text-decoration: none;
}

.teaser-ig:hover {
  background-color: #24f29c;
  color: #111;
}

.teaser-ramen {
  display: -webkit-box;
  display: -webkit-flex;
  display: -ms-flexbox;
  display: flex;
  height: 10rem;
  margin-right: -1.5rem;
  margin-bottom: 3rem;
  -webkit-box-pack: center;
  -webkit-justify-content: center;
  -ms-flex-pack: center;
  justify-content: center;
  -webkit-box-align: center;
  -webkit-align-items: center;
  -ms-flex-align: center;
  align-items: center;
  color: #24f29c;
}

.grid {
  position: absolute;
  left: 0%;
  top: auto;
  right: 0%;
  bottom: 0%;
  overflow: hidden;
  width: 100%;
  height: 70vh;
  -webkit-perspective: 56.25vh;
  perspective: 56.25vh;
  -webkit-transform: perspective(1000px);
  transform: perspective(1000px);
}

.grid-mask {
  position: absolute;
  left: 0%;
  top: 0%;
  right: 0%;
  bottom: 0%;
  background-color: transparent;
  background-image: none;
}

.grid-lines {
  position: absolute;
  left: 0%;
  top: 0%;
  right: 0%;
  bottom: 0%;
  z-index: 0;
  width: 100%;
  height: 200%;
}

.crt {
  position: fixed;
  left: 0%;
  top: 0%;
  right: 0%;
  bottom: 0%;
  z-index: 999;
  color: #24f29c;
}

.styles {
  display: none;
  color: #24f29c;
}

.container-teaser {
  width: 100%;
  max-width: 96rem;
  margin-right: auto;
  margin-left: auto;
}

.typed {
  display: inline;
}

@media screen and (max-width: 991px) {
  .teaser-title__jp,
  .teaser-title {
    font-size: 1.75rem;
    margin-top: 1rem;
  }

  .teaser-ig {
    font-size: 0.75rem;
  }

  .teaser-ramen {
    width: 10rem;
    height: 10rem;
    margin-bottom: 2rem;
  }

  .body {
    font-size: 0.85rem;
  }
}

@media screen and (max-width: 767px) {
  .teaser-component {
    padding-top: 3rem;
  }

  .teaser-desc {
    margin-top: 2rem;
    font-size: 1.2rem;
    max-width: 80%;
  }

  .teaser-ig {
    position: static;
    padding: 0.5rem 1rem;
    margin-top: 2rem;
  }
}

@media screen and (max-width: 479px) {
  .page-padding {
    padding-right: 0.5rem;
    padding-left: 0.5rem;
  }

  .grid {
    height: 50vh;
  }

  .coming-soon {
    margin-top: 1rem;
    margin-bottom: 1rem;
  }
}

@font-face {
  font-family: "Mega Man 10";
  src: url("https://assets.website-files.com/62d585353749ccfb3f8020fd/62d6f53707ac08630dd59cb3_MegaMan10Regular-mJA5.woff")
    format("woff");
  font-weight: 400;
  font-style: normal;
  font-display: swap;
}
.hero {
  font-size: clamp(30px, 8vw, 80px);
  line-height: 1;
  display: inline-block;
  color: #fff;
  letter-spacing: 5px;
  filter: drop-shadow(0 1px 3px);
}

.demo {
  height: 100px;
  background: #fff;
}

.layers {
  position: relative;
}

.layers::before,
.layers::after {
  content: attr(data-text);
  position: absolute;
  width: 110%;
  z-index: -1;
}

.layers::before {
  top: 10px;
  left: 15px;
  color: #e0287d;
}

.layers::after {
  top: 5px;
  left: -10px;
  color: #1bc7fb;
}

.single-path {
  clip-path: polygon(
    0% 12%,
    53% 12%,
    53% 26%,
    25% 26%,
    25% 86%,
    31% 86%,
    31% 0%,
    53% 0%,
    53% 84%,
    92% 84%,
    92% 82%,
    70% 82%,
    70% 29%,
    78% 29%,
    78% 65%,
    69% 65%,
    69% 66%,
    77% 66%,
    77% 45%,
    85% 45%,
    85% 26%,
    97% 26%,
    97% 28%,
    84% 28%,
    84% 34%,
    54% 34%,
    54% 89%,
    30% 89%,
    30% 58%,
    83% 58%,
    83% 5%,
    68% 5%,
    68% 36%,
    62% 36%,
    62% 1%,
    12% 1%,
    12% 34%,
    60% 34%,
    60% 57%,
    98% 57%,
    98% 83%,
    1% 83%,
    1% 53%,
    91% 53%,
    91% 84%,
    8% 84%,
    8% 83%,
    4% 83%
  );
}

.paths {
  animation: paths 5s step-end infinite;
}

@keyframes paths {
  0% {
    clip-path: polygon(
      0% 43%,
      83% 43%,
      83% 22%,
      23% 22%,
      23% 24%,
      91% 24%,
      91% 26%,
      18% 26%,
      18% 83%,
      29% 83%,
      29% 17%,
      41% 17%,
      41% 39%,
      18% 39%,
      18% 82%,
      54% 82%,
      54% 88%,
      19% 88%,
      19% 4%,
      39% 4%,
      39% 14%,
      76% 14%,
      76% 52%,
      23% 52%,
      23% 35%,
      19% 35%,
      19% 8%,
      36% 8%,
      36% 31%,
      73% 31%,
      73% 16%,
      1% 16%,
      1% 56%,
      50% 56%,
      50% 8%
    );
  }

  5% {
    clip-path: polygon(
      0% 29%,
      44% 29%,
      44% 83%,
      94% 83%,
      94% 56%,
      11% 56%,
      11% 64%,
      94% 64%,
      94% 70%,
      88% 70%,
      88% 32%,
      18% 32%,
      18% 96%,
      10% 96%,
      10% 62%,
      9% 62%,
      9% 84%,
      68% 84%,
      68% 50%,
      52% 50%,
      52% 55%,
      35% 55%,
      35% 87%,
      25% 87%,
      25% 39%,
      15% 39%,
      15% 88%,
      52% 88%
    );
  }

  30% {
    clip-path: polygon(
      0% 53%,
      93% 53%,
      93% 62%,
      68% 62%,
      68% 37%,
      97% 37%,
      97% 89%,
      13% 89%,
      13% 45%,
      51% 45%,
      51% 88%,
      17% 88%,
      17% 54%,
      81% 54%,
      81% 75%,
      79% 75%,
      79% 76%,
      38% 76%,
      38% 28%,
      61% 28%,
      61% 12%,
      55% 12%,
      55% 62%,
      68% 62%,
      68% 51%,
      0% 51%,
      0% 92%,
      63% 92%,
      63% 4%,
      65% 4%
    );
  }

  45% {
    clip-path: polygon(
      0% 33%,
      2% 33%,
      2% 69%,
      58% 69%,
      58% 94%,
      55% 94%,
      55% 25%,
      33% 25%,
      33% 85%,
      16% 85%,
      16% 19%,
      5% 19%,
      5% 20%,
      79% 20%,
      79% 96%,
      93% 96%,
      93% 50%,
      5% 50%,
      5% 74%,
      55% 74%,
      55% 57%,
      96% 57%,
      96% 59%,
      87% 59%,
      87% 65%,
      82% 65%,
      82% 39%,
      63% 39%,
      63% 92%,
      4% 92%,
      4% 36%,
      24% 36%,
      24% 70%,
      1% 70%,
      1% 43%,
      15% 43%,
      15% 28%,
      23% 28%,
      23% 71%,
      90% 71%,
      90% 86%,
      97% 86%,
      97% 1%,
      60% 1%,
      60% 67%,
      71% 67%,
      71% 91%,
      17% 91%,
      17% 14%,
      39% 14%,
      39% 30%,
      58% 30%,
      58% 11%,
      52% 11%,
      52% 83%,
      68% 83%
    );
  }

  76% {
    clip-path: polygon(
      0% 26%,
      15% 26%,
      15% 73%,
      72% 73%,
      72% 70%,
      77% 70%,
      77% 75%,
      8% 75%,
      8% 42%,
      4% 42%,
      4% 61%,
      17% 61%,
      17% 12%,
      26% 12%,
      26% 63%,
      73% 63%,
      73% 43%,
      90% 43%,
      90% 67%,
      50% 67%,
      50% 41%,
      42% 41%,
      42% 46%,
      50% 46%,
      50% 84%,
      96% 84%,
      96% 78%,
      49% 78%,
      49% 25%,
      63% 25%,
      63% 14%
    );
  }

  90% {
    clip-path: polygon(
      0% 41%,
      13% 41%,
      13% 6%,
      87% 6%,
      87% 93%,
      10% 93%,
      10% 13%,
      89% 13%,
      89% 6%,
      3% 6%,
      3% 8%,
      16% 8%,
      16% 79%,
      0% 79%,
      0% 99%,
      92% 99%,
      92% 90%,
      5% 90%,
      5% 60%,
      0% 60%,
      0% 48%,
      89% 48%,
      89% 13%,
      80% 13%,
      80% 43%,
      95% 43%,
      95% 19%,
      80% 19%,
      80% 85%,
      38% 85%,
      38% 62%
    );
  }

  1%,
  7%,
  33%,
  47%,
  78%,
  93% {
    clip-path: none;
  }
}

.movement {
  position: relative;
  animation: movement 8s step-end infinite;
}

@keyframes movement {
  0% {
    top: 0px;
    left: -20px;
  }

  15% {
    top: 10px;
    left: 10px;
  }

  60% {
    top: 5px;
    left: -10px;
  }

  75% {
    top: -5px;
    left: 20px;
  }

  100% {
    top: 10px;
    left: 5px;
  }
}

.opacity {
  animation: opacity 5s step-end infinite;
}

@keyframes opacity {
  0% {
    opacity: 0.1;
  }

  5% {
    opacity: 0.7;
  }

  30% {
    opacity: 0.4;
  }

  45% {
    opacity: 0.6;
  }

  76% {
    opacity: 0.4;
  }

  90% {
    opacity: 0.8;
  }

  1%,
  7%,
  33%,
  47%,
  78%,
  93% {
    opacity: 0;
  }
}

.font {
  animation: font 7s step-end infinite;
}

@keyframes font {
  0% {
    font-weight: 100;
    color: #e0287d;
    filter: blur(3px);
  }

  20% {
    font-weight: 500;
    color: #fff;
    filter: blur(0);
  }

  50% {
    font-weight: 300;
    color: #1bc7fb;
    filter: blur(2px);
  }

  60% {
    font-weight: 700;
    color: #fff;
    filter: blur(0);
  }

  90% {
    font-weight: 500;
    color: #e0287d;
    filter: blur(6px);
  }
}

.glitch {
  position: relative;
  width: 100%;
  overflow: hidden;
}

.glitch span {
  animation: paths 5s step-end infinite;
}

.glitch::before {
  animation: paths 5s step-end infinite, opacity 5s step-end infinite,
    font 8s step-end infinite, movement 10s step-end infinite;
}

.glitch::after {
  animation: paths 5s step-end infinite, opacity 5s step-end infinite,
    font 7s step-end infinite, movement 8s step-end infinite;
}

.hero-container {
  position: relative;
  padding: 5vw 0;
  text-align: center;
  overflow: hidden;
}

@media screen and (max-width: 767px) {
  .hero-container {
    padding: 3vw 0;
  }
}

@media screen and (max-width: 479px) {
  .hero-container {
    padding: 2vw 0;
  }
}
